import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Box } from "@mui/material";
import useWindowDimensions from "../../hooks/use-window-dimensions";
const markup = () => {
  return {
    __html: `<iframe
frameborder="0"
border="0"
cellspacing="0"
width="100%"
height="100%"
scrolling="no"
style="border-style: none;overflow:hidden;"
src="https://www.naks.org/jml/api-slider"
/>`,
  };
};

export default function MainSlider() {
  const { width, height } = useWindowDimensions();
  //const [value, setValue] = useState(width);
  // useEffect(() => {}, [value]);
  const dim = { width: "100%", height: width * 0.4866 };

  return (
    <div
      className="mainslider"
      style={dim}
      dangerouslySetInnerHTML={markup()}
    />
  );
}
