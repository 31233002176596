import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import { Container, Box, Grid } from "@mui/material";

type Props = {
  title?: string;
};

class AuthLayout extends React.Component<Props> {
  render() {
    return (
      <Container
        sx={{
          background: "linear-gradient(to bottom, #7c5140, #ffffff 200px)",
          p: "0px",
          width: "100vw",
          height: "100vh",
          border: "0px solid #0d8b51",
        }}
        maxWidth="xs"
      >
        <Box
          display="grid"
          sx={{
            border: "0px solid #000000",
            height: "100%",
          }}
          gridTemplateRows="100px 1fr"
        >
          <Box sx={{ border: "0px solid #7a3c3c" }}>
            <Header message={this.props.title} />
          </Box>
          <Box sx={{ border: "0px solid #5c0e0e" }}>
            <Box
              sx={{
                border: "0px solid #ff0000",
                justifyContent: "center",
                p: 0,
                m: 0,
                textAlign: "center",
                height: "calc(100vh - 130px)",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}
export default AuthLayout;
