import * as React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArticleService from "../services/article.service";
import articleType from "../common/ArticleType";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";

type NotificationFetch = {
  notifications: Array<any>;
  isFetching: boolean;
};

export default function Latest() {
  const [data, setData] = useState<NotificationFetch>({
    notifications: [],
    isFetching: true,
  });

  useEffect(() => {
    ArticleService.getLatest().then(
      (response) => {
        //setData({userinfo:response.data.data.data,isFetching:false});
        setData({ notifications: response.data.data.data, isFetching: false });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);

  return (
    <React.Fragment>
      {data.isFetching ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          elevation={2}
          sx={{
            maxWidth: `100%`,
            maxHeight: "45%",
            p: "2px",
            m: "0 5px 5px 5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <List
            sx={{
              maxHeight: "40vh",
              overflow: "auto",
              border: "1px solid #ccc",
              p: "2px",
            }}
          >
            {data.notifications?.map(
              (notification: articleType, index: number) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      p: "2px 2px 1px 2px",
                      borderBottom: "1px solid #ccc",
                    }}
                    key={uuidv4()}
                  >
                    <ListItemText
                      primary={notification.category}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {notification.title}
                          </Typography>

                          {notification.created_date}
                        </React.Fragment>
                      }
                    />
                    {/* <Divider
                      variant="inset"
                      component="div"
                      sx={{ ml: "0px" }}
                    /> */}
                  </ListItem>
                );
              }
            )}
          </List>
          {/* <Button href="/articles" variant="contained" sx={{ m: "2px 0px" }}>
            View Detail
          </Button> */}
        </Paper>
      )}
    </React.Fragment>
  );
}
