import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";
import BgImage from "../../assets/headerbg.png";
type Props = {
  title?: string;
};
class PageLayout extends React.Component<Props> {
  render() {
    return (
      <Container
        sx={{
          // background: "linear-gradient(to bottom, #ffc23d 50px, #ffffff 200px)",
          background: `url(${BgImage}) top center no-repeat`,
          p: "0px",
          width: "100vw",
          height: "100vh",
          border: "0px solid #0d8b51",
        }}
        maxWidth="md"
      >
        <Box
          display="grid"
          sx={{
            border: "0px solid #000000",
            height: "100vh",
          }}
          gridTemplateRows="100px 1fr 56px"
        >
          <Box sx={{ border: "0px solid #7a3c3c" }}>
            <Header message={this.props.title} />
          </Box>

          <Box
            sx={{
              border: "0px solid #ff0000",
              justifyContent: "center",
              p: 0,
              m: 0,
              textAlign: "center",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Box>

          <Box sx={{ border: "0px solid #d6d6d6" }}>
            <Footer />
          </Box>
        </Box>
      </Container>
    );
  }
}
export default PageLayout;
