import React from "react";

import Box from "@mui/material/Box";
import TopLogo from "../ui/TopLogo";

function Header(props: any) {
  return (
    <Box
      sx={{
        p: "5px",
      }}
    >
      <TopLogo />
    </Box>
  );
}
export default Header;
