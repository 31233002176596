import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AuthService from "../services/auth.service";
import { useState, useEffect } from "react";
import { SettingsInputAntennaTwoTone } from "@mui/icons-material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import QRCode from "react-qr-code";
import { render } from "@testing-library/react";
import IdInfo from "../common/IdInfo";

const effectiveStr = "DATE OF EXPIRY 12/31/" + new Date().getFullYear();
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

//const currentUser = AuthService.getCurrentUser();

export default function IdCard(info: IdInfo) {
  return (
    <Box
      display="grid"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        fontFamily: "KoddiUDOnGothic",
        fontWeight: "bold",
        height: "calc(100vh - 160px)",
        width: "1",
        padding: 0,
      }}
    >
      <Grid item display="flex" justifyContent="center" xs={12}>
        <Card
          sx={{
            maxWidth: "27vh",
            border: "0px solid #1a1a19",
            borderRadius: "3vh",
          }}
        >
          <CardMedia component="img" alt="My Photo" image={info.photoUrl} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Item
          sx={{
            padding: "1vh",
            boxShadow: "none",
            backgroundColor: "",
            fontSize: "3.5vh",
            color: "#1a1a19",
            textTransform: "upper",
            marginTop: "0.5vh",
            marginBottom: "0.5vh",
            fontWeight: "bold",
          }}
        >
          {info.name}
        </Item>
      </Grid>

      <Grid item xs={12}>
        <QRCode value={info.naksID} size={160} />
      </Grid>
      <Grid item xs={12}>
        <Item
          sx={{
            padding: "1vh 0 0 0",
            boxShadow: "none",
            backgroundColor: "",
            color: "#1a1a19",
            fontSize: "2.5vh",
          }}
        >
          {info.naksID}{" "}
        </Item>
      </Grid>
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Item
          sx={{
            padding: "0",
            boxShadow: "none",
            backgroundColor: "",
            color: "#353535",
            fontSize: "1.5vh",
          }}
        >
          {effectiveStr}
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item
          sx={{
            padding: "0.5vh",
            boxShadow: "none",
            backgroundColor: "",
            fontSize: "3vh",
            color: "#1a1a19",
            fontWeight: "bold",
          }}
        >
          {info.school}
        </Item>
      </Grid>

      <Grid
        item
        style={{
          width: "98vw",
          border: "0px solid #6e2828",
          padding: "0.5vh 0",
        }}
      >
        <Item
          sx={{
            fontSize: "2.5vh",
            fontWeight: "regular",
            textTransform: "uppercase",
            backgroundColor: "#000",
            color: "#FFF",
          }}
        >
          {info.chapter}
        </Item>
      </Grid>
    </Box>
  );
}
