import { Box } from "@mui/material";
import React from "react";
import BottomNavigationBar from "./BottomNavigationBar";
function Footer() {
  return (
    <Box
      sx={{
        border: "0px solid #ff0000",

        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigationBar />
    </Box>
  );
}
export default Footer;
