import { createSlice } from '@reduxjs/toolkit'

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    value: 0,
    id: 0,
    title: 'Home'
  },
  reducers: {
    updateTask: (state, action) => {

      state.id = action.payload.id;
      state.title = action.payload.title;
    },

    nextTask: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    prevTask: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateTask } = taskSlice.actions

export default taskSlice.reducer