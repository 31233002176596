import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = responsiveFontSizes(createTheme({
  spacing: 4,
  typography: {
    fontFamily: [
      'KoddiUDOnGothic', "sans-serif"
    ].join(','),
    h1: {
      fontSize: '5rem',
      fontFamily: 'Raleway',
    },
    h2: {
      fontSize: '3.5rem',
      fontFamily: 'Open Sans',
      fontStyle: 'bold',
    },
    h3: {
      fontSize: '2.5rem',
      fontFamily: 'Roboto',
    },
  },
  palette: {
    background: {
      default: "#fff" //green
    },
    primary: {
      main: '#2B37D4',//indigo
    },
    secondary: {
      main: '#E769A6',//pink
    },
    error: {
      main: '#D72A2A',//red
    },
    warning: {
      main: '#FC7B09',//orange
    },
    info: {
      main: '#1a5baa',//gray
    },
    success: {
      main: '#09FE00',//green
    },
    text: {
      primary: '#000000',//black
      secondary: '#888888',//white
    },
  },
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a5baa'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0)'
        }
      }
    }

  }
}));


export default theme;