import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import AuthService from "../../services/auth.service";
import { useState, useEffect } from "react";
import { SettingsInputAntennaTwoTone } from "@mui/icons-material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import QRCode from "react-qr-code";

import { boolean } from "yup";
import ArticleService from "../../services/article.service";
import articleType from "../../common/ArticleType";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

//const currentUser = AuthService.getCurrentUser();

type ArticleFetch = {
  articles: Array<articleType>;
  isFetching: boolean;
};

export default function Notification() {
  const [data, setData] = useState<ArticleFetch>({
    articles: [],
    isFetching: false,
  });

  useEffect(() => {
    ArticleService.getNotification().then(
      (response: any) => {
        setData({ articles: response.data.data.data, isFetching: false });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);

  return (
    <React.Fragment>
      {data.isFetching ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%", display: "flex" }}
        >
          <CircularProgress sx={{ color: "#0026ff" }} />
        </Box>
      ) : (
        <>
          <Typography
            sx={{ display: "inline", mt: "10px" }}
            component="span"
            variant="h4"
            color="#fffbfb"
          >
            Notification
          </Typography>
          <Box sx={{ height: "100%", p: 0, m: 0, mt: "20px", width: "100%" }}>
            <List sx={{}}>
              {data.articles.map((article: articleType, index: number) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    key={index}
                    sx={{ borderBottom: 0.5 }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="#a3a3a3"
                        >
                          {article.category}
                        </Typography>
                      }
                      secondary={
                        <Box component="div">
                          <Typography
                            component="span"
                            variant="body2"
                            color="#336eee"
                          >
                            {article.author.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            sx={{
                              display: "inline",
                              fontSize: "0.8em",
                              color: "#cc3366",
                              ml: "5px",
                            }}
                          >
                            {article.created_date}
                          </Typography>

                          <Typography
                            component="div"
                            variant="body2"
                            color="text.primary"
                            sx={{ fontWeight: "bold" }}
                          >
                            {article.title}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </React.Fragment>
  );
}
