import axios from "axios";
import IdInfo from "../common/IdInfo";
const API_URL_LOGIN = "https://naks.org/jml/index.php?option=com_api&app=users&resource=login&format=raw";
const API_URL_REGISTER = "https://naks.org/jml/index.php?option=com_api&app=users&resource=login&format=raw";
const API_URL_TEACHERID = "https://naks.org/jml/index.php?option=com_api&app=teacherid&resource=id&format=raw&";
const NAKS_URL = "https://naks.org/jml/"

class AuthService {
  /*
  *  LOGIN First
  *
  */
  async login(username: string, password: string): Promise<any> {

    const params = new URLSearchParams({
      username: username,
      password: password
    });

    return axios
      .post(API_URL_LOGIN, params)
      .then(response => {
        if (response.data.data.auth) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          return Promise.resolve(response.data);
        } else {
          return Promise.reject(response.data)
        }

      })
      .catch(
        (error) => {
          return Promise.reject(error);
        }
      )
  }




  fetchTeacherinfo() {

    let token = this.getCurrentUser().auth;
    let id = this.getCurrentUser().id;
    return axios
      .get(API_URL_TEACHERID + "id=" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {

        AuthService.saveIdinfo(response.data.data);
        return response.data.data;
      });
  }

  getIdInfoFromStorage(): Array<IdInfo> | null {
    const idinfoStr = localStorage.getItem("idinfo");

    if (idinfoStr) return JSON.parse(idinfoStr);
    return null;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("idinfo");
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_URL_REGISTER, {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  getCurrentUserId() {
    const userStr = localStorage.getItem("userid");
    if (userStr) return JSON.parse(userStr)[0];
    return null;
  }

  static saveIdinfo(data: any) {

    let info = [{
      naksID: data.teacher_id,
      photoUrl: NAKS_URL + data.idphoto,
      name: data.firstname + " " + data.lastname,
      school: !data.my_school_name_when_not_in_the_list ?
        data.schoolName : data.inputschoolname,
      chapter: data.chapterName + " CHAPTER"
    },
    {
      naksID: data.teacher_id,
      photoUrl: NAKS_URL + data.idphoto,
      name: data.name_in_korean,
      school: data.schoolNameInKorean,
      chapter: data.chapterNameInKorean
    }];
    localStorage.setItem("idinfo", JSON.stringify(info));

  }
}

export default new AuthService();