import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import IdIcon from "@mui/icons-material/Badge";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import authService from "../../services/auth.service";
import { cp } from "fs";

export default function LabelBottomNavigation() {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname.substring(1));

  useEffect(() => {
    console.log(value);
  }, [location]);

  const handleChange = (event: any, newValue: string) => {
    if (newValue === "logout") {
      authService.logout();
    }
    setValue(newValue);
  };

  return (
    <BottomNavigation
      showLabels
      sx={{
        color: "#79a3f1",
        "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
          color: "#8ec1eb",
        },
        "& .Mui-selected": {
          "& .MuiBottomNavigationAction-label": {
            fontSize: (theme) => theme.typography.caption,
            transition: "none",
            fontWeight: "bold",
            lineHeight: "20px",
          },
          "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
            color: "#fff",
          },
        },
        width: "100%",
      }}
      value={value}
      onChange={handleChange}
    >
      <BottomNavigationAction
        label="Home"
        value="home"
        component={NavLink}
        to="/"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        label="Teacher ID"
        value="teacherid"
        component={NavLink}
        to="/teacherid"
        icon={<IdIcon />}
      />
      <BottomNavigationAction
        label="Notice"
        value="news"
        component={NavLink}
        to="/notice"
        icon={<ArticleIcon />}
      />
      <BottomNavigationAction
        label="Logout"
        value="logout"
        component={NavLink}
        to="/login"
        icon={<LogoutIcon />}
      />
    </BottomNavigation>
  );
}
