import * as React from "react";
import Userinfo from "../Userinfo";
import Latest from "../Latest";
import MainSlider from "../external/MainSlider";
export default function Home() {
  return (
    <React.Fragment>
      <MainSlider />
      <Latest />
      <Userinfo />
    </React.Fragment>
  );
}
