import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/CardContent";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import AuthService from "../services/auth.service";
import CircularProgress from "@mui/material/CircularProgress";
import IdInfo from "../common/IdInfo";
import UserDataFetch from "../common/UserDataFetch";

export default function Userinfo() {
  const [data, setData] = useState<UserDataFetch>({
    userinfo: { naksID: "" },
    isFetching: true,
  });

  useEffect(() => {
    let idinfos: any = AuthService.getIdInfoFromStorage();
    let idinfo: IdInfo | null;

    idinfo = idinfos ? idinfos[0] : null;

    if (idinfo) {
      setData({ userinfo: idinfo, isFetching: false });
    } else {
      AuthService.fetchTeacherinfo().then(
        (response) => {
          setData({
            userinfo: (AuthService.getIdInfoFromStorage() as Array<IdInfo>)[0],
            isFetching: false,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          //setIdinfo();
        }
      );
    }
  }, []);

  return (
    <React.Fragment>
      {data.isFetching ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: "1vh" }}>
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              border: "0px solid #1a1a19",
              borderRadius: "0vh",
              boxShadow: 2,
              m: 0,
              p: 0,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", m: 0, p: 0 }}>
              <CardMedia
                component="img"
                sx={{ width: "40%" }}
                alt="My Photo"
                image={data.userinfo.photoUrl}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto", width: "100%" }}>
                  <Typography
                    sx={{
                      padding: "2px",
                      boxShadow: "none",
                      backgroundColor: "",
                      fontSize: "2vh",
                      color: "#1a1a19",
                      textTransform: "upper",
                      border: 0,
                      textAlign: "center",
                    }}
                  >
                    {data.userinfo.name}
                  </Typography>
                  <Typography
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "",
                      fontSize: "1.5vh",
                      color: "#1a1a19",
                      textTransform: "upper",
                    }}
                  >
                    {data.userinfo.school}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <IconButton aria-label="play/pause">
                  <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                </IconButton> */}
                    <Button
                      href="/teacherid"
                      variant="contained"
                      sx={{ width: 1 }}
                    >
                      View ID Card
                    </Button>
                  </Box>
                </CardContent>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}
