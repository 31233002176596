import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AuthService from "../../services/auth.service";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IdCardPage from "../IdCard";
import IdInfo from "../../common/IdInfo";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

//const currentUser = AuthService.getCurrentUser();
type IdinfoFetch = {
  idinfos: Array<IdInfo>;
  isFetching: boolean;
};

export default function Teacherid() {
  const [data, setData] = useState<IdinfoFetch>({
    idinfos: [],
    isFetching: true,
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (AuthService.getIdInfoFromStorage()) {
      setData({
        idinfos: AuthService.getIdInfoFromStorage() as Array<IdInfo>,
        isFetching: false,
      });
    } else {
      AuthService.fetchTeacherinfo().then(
        (response) => {
          setData({
            idinfos: AuthService.getIdInfoFromStorage() as Array<IdInfo>,
            isFetching: false,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // setData({});
        }
      );
    }
  }, []);

  return (
    <>
      {data.isFetching ? (
        <Box sx={{ height: "100%", display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{ height: "100%", width: "100%", border: "0px solid #efe235" }}
        >
          <Slider {...settings}>
            <IdCardPage {...(data.idinfos[0] as IdInfo)} key="en" />
            <IdCardPage {...(data.idinfos[1] as IdInfo)} key="ko" />
          </Slider>
        </Box>
      )}
    </>
  );
}
