import React, { Component } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/themes/naks-theme";
import "./assets/css/naks.css";

import Login from "./components/page/Login";
import Teacherid from "./components/page/Teacherid";
import Home from "./components/page/Home";
import EventBus from "./common/EventBus";
import IUser from "./types/user.type";
import AuthService from "./services/auth.service";
import PageLayout from "./components/layout/PageLayout";
import AuthLayout from "./components/layout/AuthLayout";
import Notification from "./components/page/Notification";

type ProtectedProps = {
  redirectPath?: string;
  children?: JSX.Element;
};
const ProtectedRoute = ({
  redirectPath = "/login",
  children,
}: ProtectedProps) => {
  return AuthService.getCurrentUser() ? (
    children ? (
      children
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};
type Props = {};

type State = {
  currentUser: IUser | undefined;
};
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
  }

  logOut() {
    AuthService.logout();
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Login />} />
            </Route>

            <Route element={<PageLayout />}>
              <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
              </Route>
              <Route path="/notice" element={<Notification />} />
              <Route path="/home" element={<ProtectedRoute />}>
                <Route path="/home" element={<Home />} />
              </Route>
              <Route path="/teacherid" element={<ProtectedRoute />}>
                <Route path="/teacherid" element={<Teacherid />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
