import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

interface RouterProps {
  history: string;
}

type Props = {};
type State = {
  username: string;
  password: string;
  loading: boolean;
  message: string;
};
// const withRouter = WrappedComponent:React.Component => props:any => {
//   const navigate = useNavigate();
//   // etc... other react-router-dom v6 hooks

//   return (
//     <WrappedComponent
//       {...props}
//       navigate={navigate}
//       // etc...
//     />
//   );
// };

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const loginUser = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;

    AuthService.login(username, password).then(
      (response) => {
        navigate("/teacherid");
      },
      (error) => {
        console.info(error);
        const resMessage = error.err_code + ":" + error.err_msg.toString();
        setMessage(resMessage);
      }
    );
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    let formdata: FormData;
    if (event.currentTarget) {
      formdata = new FormData(event.currentTarget as HTMLFormElement);

      loginUser({
        username: formdata.get("email") as string,
        password: formdata.get("password") as string,
      });
    } else {
      console.log("no formdata");
    }

    //  console.log("result = " + typeof result);
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "#2d2e99" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Typography sx={{ color: "#ff0000" }}>{message}</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: "80vw" }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address / Username"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          size="medium"
          variant="contained"
          sx={{ mt: 3, mb: 2, width: "100%" }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href="https://www.naks.org/jml/login?view=reset"
              variant="body2"
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="https://www.naks.org/jml/login?view=registration"
              variant="body2"
            >
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
