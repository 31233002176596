import axios from "axios";
import AuthService from "./auth.service";
const API_URL_ARTICLE_FEATURED = "https://naks.org/jml/index.php?option=com_api&app=articles&resource=article&format=raw&featured=1&";
const API_URL_ARTICLE_LATEST = "https://naks.org/jml/index.php?option=com_api&app=articles&resource=latest&format=raw&";
const API_URL_ARTICLE_NOTICE = "https://naks.org/jml/index.php?option=com_api&app=articles&resource=teacherid&format=raw&catid=202&";



class ArticleService {
  getLatest() {
    let token = AuthService.getCurrentUser().auth;
    //let id = authService.getCurrentUser().id;
    return axios
      .get(API_URL_ARTICLE_LATEST, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
        console.log("#############response teacher id" + response.data);

        return response;
      })

      ;
  }



  getNotification() {
    let token = AuthService.getCurrentUser().auth;
    //let id = authService.getCurrentUser().id;
    return axios
      .get(API_URL_ARTICLE_NOTICE, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
        console.log("#############response teacher id" + response.data);

        return response;
      })

      ;
  }
}
export default new ArticleService();